.email-header { grid-area: email-header; }
.total-sent { grid-area: total-sent; }
.total-delivered { grid-area: total-delivered; }
.delivery-rate { grid-area: delivery-rate; }
.delivery-table { grid-area: delivery-table; }
.open-rate { grid-area: open-rate; }
.total-opened { grid-area: total-opened; }
.delivery-table { grid-area: delivery-table; }
.click-to-open { grid-area: click-to-open; }
.complaint-rate { grid-area: complaint-rate; }
.delivery-status { grid-area: delivery-status; }
.delivered-vs-opened { grid-area: delivered-vs-opened; }

.grid-container {
  display: grid;
  grid-template-areas:
    'email-header    email-header    email-header    email-header        email-header'
    'total-sent      total-delivered delivery-rate   delivered-vs-opened delivered-vs-opened'
    'total-sent      open-rate       total-opened    delivered-vs-opened delivered-vs-opened'
    'delivery-status delivery-status delivery-status click-to-open       complaint-rate'
    'delivery-status delivery-status delivery-status delivery-table      delivery-table';
  gap: 10px;
}
