.arrow {
  margin-left: 13px !important;
  display: inline-block !important;
  border: solid #606977 !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(45deg) !important;
  top: 2px !important;
  margin-bottom: 2px !important;
  left: 36px !important;
}

.up {
  transform: rotate(-135deg) !important;
  margin-top: 10px !important;
}

.country {
  margin-bottom: 2px !important;
}

.country:hover {
  background-color: rgba(49, 59, 75, 0.50) !important;
  border-radius: 4px !important;
}

.highlight {
  background-color: rgba(130, 230, 184, 0.10) !important;
  border-radius: 4px !important;
}

.highlight:hover {
  background-color: rgba(130, 230, 184, 0.10) !important;
}

.flag {
  border-radius: 50%;
  margin-top: 0px !important;
  width: 30px !important;
  height: 30px !important;
  background-size: cover;
  background-position: unset!important;
  border: 1px solid #9bafd0;
  background-image: url("./flags/us.svg")!important;
}

.react-tel-input .country-list .flag {
  left: 4px!important;
}

.selected-flag {
  margin-top: -14px !important;
}

.flag.af {
  background-image: url("./flags/af.svg") !important;
}

.flag.af {
  background-image: url("./flags/af.svg") !important;
}
.flag.al {
  background-image: url("./flags/al.svg") !important;
}
.flag.dz {
  background-image: url("./flags/dz.svg") !important;
}
.flag.ad {
  background-image: url("./flags/ad.svg") !important;
}
.flag.ao {
  background-image: url("./flags/ao.svg") !important;
}
.flag.ag {
  background-image: url("./flags/ag.svg") !important;
}
.flag.ar {
  background-image: url("./flags/ar.svg") !important;
}
.flag.am {
  background-image: url("./flags/am.svg") !important;
}
.flag.aw {
  background-image: url("./flags/aw.svg") !important;
}
.flag.au {
  background-image: url("./flags/au.svg") !important;
}
.flag.at {
  background-image: url("./flags/at.svg") !important;
}
.flag.az {
  background-image: url("./flags/az.svg") !important;
}
.flag.bs {
  background-image: url("./flags/bs.svg") !important;
}
.flag.bh {
  background-image: url("./flags/bh.svg") !important;
}
.flag.bd {
  background-image: url("./flags/bd.svg") !important;
}
.flag.bb {
  background-image: url("./flags/bb.svg") !important;
}
.flag.by {
  background-image: url("./flags/by.svg") !important;
}
.flag.be {
  background-image: url("./flags/be.svg") !important;
}
.flag.bz {
  background-image: url("./flags/bz.svg") !important;
}
.flag.bj {
  background-image: url("./flags/bj.svg") !important;
}
.flag.bt {
  background-image: url("./flags/bt.svg") !important;
}
.flag.bo {
  background-image: url("./flags/bo.svg") !important;
}
.flag.ba {
  background-image: url("./flags/ba.svg") !important;
}
.flag.bw {
  background-image: url("./flags/bw.svg") !important;
}
.flag.br {
  background-image: url("./flags/br.svg") !important;
}
.flag.io {
  background-image: url("./flags/io.svg") !important;
}
.flag.bn {
  background-image: url("./flags/bn.svg") !important;
}
.flag.bg {
  background-image: url("./flags/bg.svg") !important;
}
.flag.bf {
  background-image: url("./flags/bf.svg") !important;
}
.flag.bi {
  background-image: url("./flags/bi.svg") !important;
}
.flag.kh {
  background-image: url("./flags/kh.svg") !important;
}
.flag.cm {
  background-image: url("./flags/cm.svg") !important;
}
.flag.ca {
  background-image: url("./flags/ca.svg") !important;
}
.flag.cv {
  background-image: url("./flags/cv.svg") !important;
}
.flag.bq {
  background-image: url("./flags/bq.svg") !important;
}
.flag.cf {
  background-image: url("./flags/cf.svg") !important;
}
.flag.td {
  background-image: url("./flags/td.svg") !important;
}
.flag.cl {
  background-image: url("./flags/cl.svg") !important;
}
.flag.cn {
  background-image: url("./flags/cn.svg") !important;
}
.flag.co {
  background-image: url("./flags/co.svg") !important;
}
.flag.km {
  background-image: url("./flags/km.svg") !important;
}
.flag.cd {
  background-image: url("./flags/cd.svg") !important;
}
.flag.cg {
  background-image: url("./flags/cg.svg") !important;
}
.flag.cr {
  background-image: url("./flags/cr.svg") !important;
}
.flag.ci {
  background-image: url("./flags/ci.svg") !important;
}
.flag.hr {
  background-image: url("./flags/hr.svg") !important;
}
.flag.cu {
  background-image: url("./flags/cu.svg") !important;
}
.flag.cw {
  background-image: url("./flags/cw.svg") !important;
}
.flag.cy {
  background-image: url("./flags/cy.svg") !important;
}
.flag.cz {
  background-image: url("./flags/cz.svg") !important;
}
.flag.dk {
  background-image: url("./flags/dk.svg") !important;
}
.flag.dj {
  background-image: url("./flags/dj.svg") !important;
}
.flag.dm {
  background-image: url("./flags/dm.svg") !important;
}
.flag.do {
  background-image: url("./flags/do.svg") !important;
}
.flag.ec {
  background-image: url("./flags/ec.svg") !important;
}
.flag.eg {
  background-image: url("./flags/eg.svg") !important;
}
.flag.sv {
  background-image: url("./flags/sv.svg") !important;
}
.flag.gq {
  background-image: url("./flags/gq.svg") !important;
}
.flag.er {
  background-image: url("./flags/er.svg") !important;
}
.flag.ee {
  background-image: url("./flags/ee.svg") !important;
}
.flag.et {
  background-image: url("./flags/et.svg") !important;
}
.flag.fj {
  background-image: url("./flags/fj.svg") !important;
}
.flag.fi {
  background-image: url("./flags/fi.svg") !important;
}
.flag.fr {
  background-image: url("./flags/fr.svg") !important;
}
.flag.gf {
  background-image: url("./flags/gf.svg") !important;
}
.flag.pf {
  background-image: url("./flags/pf.svg") !important;
}
.flag.ga {
  background-image: url("./flags/ga.svg") !important;
}
.flag.gm {
  background-image: url("./flags/gm.svg") !important;
}
.flag.ge {
  background-image: url("./flags/ge.svg") !important;
}
.flag.de {
  background-image: url("./flags/de.svg") !important;
}
.flag.gh {
  background-image: url("./flags/gh.svg") !important;
}
.flag.gr {
  background-image: url("./flags/gr.svg") !important;
}
.flag.gd {
  background-image: url("./flags/gd.svg") !important;
}
.flag.gp {
  background-image: url("./flags/gp.svg") !important;
}
.flag.gu {
  background-image: url("./flags/gu.svg") !important;
}
.flag.gt {
  background-image: url("./flags/gt.svg") !important;
}
.flag.gn {
  background-image: url("./flags/gn.svg") !important;
}
.flag.gw {
  background-image: url("./flags/gw.svg") !important;
}
.flag.gy {
  background-image: url("./flags/gy.svg") !important;
}
.flag.ht {
  background-image: url("./flags/ht.svg") !important;
}
.flag.hn {
  background-image: url("./flags/hn.svg") !important;
}
.flag.hk {
  background-image: url("./flags/hk.svg") !important;
}
.flag.hu {
  background-image: url("./flags/hu.svg") !important;
}
.flag.is {
  background-image: url("./flags/is.svg") !important;
}
.flag.in {
  background-image: url("./flags/in.svg") !important;
}
.flag.id {
  background-image: url("./flags/id.svg") !important;
}
.flag.ir {
  background-image: url("./flags/ir.svg") !important;
}
.flag.iq {
  background-image: url("./flags/iq.svg") !important;
}
.flag.ie {
  background-image: url("./flags/ie.svg") !important;
}
.flag.il {
  background-image: url("./flags/il.svg") !important;
}
.flag.it {
  background-image: url("./flags/it.svg") !important;
}
.flag.jm {
  background-image: url("./flags/jm.svg") !important;
}
.flag.jp {
  background-image: url("./flags/jp.svg") !important;
}
.flag.jo {
  background-image: url("./flags/jo.svg") !important;
}
.flag.kz {
  background-image: url("./flags/kz.svg") !important;
}
.flag.ke {
  background-image: url("./flags/ke.svg") !important;
}
.flag.ki {
  background-image: url("./flags/ki.svg") !important;
}
.flag.xk {
  background-image: url("./flags/xk.svg") !important;
}
.flag.kw {
  background-image: url("./flags/kw.svg") !important;
}
.flag.kg {
  background-image: url("./flags/kg.svg") !important;
}
.flag.la {
  background-image: url("./flags/la.svg") !important;
}
.flag.lv {
  background-image: url("./flags/lv.svg") !important;
}
.flag.lb {
  background-image: url("./flags/lb.svg") !important;
}
.flag.ls {
  background-image: url("./flags/ls.svg") !important;
}
.flag.lr {
  background-image: url("./flags/lr.svg") !important;
}
.flag.ly {
  background-image: url("./flags/ly.svg") !important;
}
.flag.li {
  background-image: url("./flags/li.svg") !important;
}
.flag.lt {
  background-image: url("./flags/lt.svg") !important;
}
.flag.lu {
  background-image: url("./flags/lu.svg") !important;
}
.flag.mo {
  background-image: url("./flags/mo.svg") !important;
}
.flag.mk {
  background-image: url("./flags/mk.svg") !important;
}
.flag.mg {
  background-image: url("./flags/mg.svg") !important;
}
.flag.mw {
  background-image: url("./flags/mw.svg") !important;
}
.flag.my {
  background-image: url("./flags/my.svg") !important;
}
.flag.mv {
  background-image: url("./flags/mv.svg") !important;
}
.flag.ml {
  background-image: url("./flags/ml.svg") !important;
}
.flag.mt {
  background-image: url("./flags/mt.svg") !important;
}
.flag.mh {
  background-image: url("./flags/mh.svg") !important;
}
.flag.mq {
  background-image: url("./flags/mq.svg") !important;
}
.flag.mr {
  background-image: url("./flags/mr.svg") !important;
}
.flag.mu {
  background-image: url("./flags/mu.svg") !important;
}
.flag.mx {
  background-image: url("./flags/mx.svg") !important;
}
.flag.fm {
  background-image: url("./flags/fm.svg") !important;
}
.flag.md {
  background-image: url("./flags/md.svg") !important;
}
.flag.mc {
  background-image: url("./flags/mc.svg") !important;
}
.flag.mn {
  background-image: url("./flags/mn.svg") !important;
}
.flag.me {
  background-image: url("./flags/me.svg") !important;
}
.flag.ma {
  background-image: url("./flags/ma.svg") !important;
}
.flag.mz {
  background-image: url("./flags/mz.svg") !important;
}
.flag.mm {
  background-image: url("./flags/mm.svg") !important;
}
.flag.na {
  background-image: url("./flags/na.svg") !important;
}
.flag.nr {
  background-image: url("./flags/nr.svg") !important;
}
.flag.np {
  background-image: url("./flags/np.svg") !important;
}
.flag.nl {
  background-image: url("./flags/nl.svg") !important;
}
.flag.nc {
  background-image: url("./flags/nc.svg") !important;
}
.flag.nz {
  background-image: url("./flags/nz.svg") !important;
}
.flag.ni {
  background-image: url("./flags/ni.svg") !important;
}
.flag.ne {
  background-image: url("./flags/ne.svg") !important;
}
.flag.ng {
  background-image: url("./flags/ng.svg") !important;
}
.flag.kp {
  background-image: url("./flags/kp.svg") !important;
}
.flag.no {
  background-image: url("./flags/no.svg") !important;
}
.flag.om {
  background-image: url("./flags/om.svg") !important;
}
.flag.pk {
  background-image: url("./flags/pk.svg") !important;
}
.flag.pw {
  background-image: url("./flags/pw.svg") !important;
}
.flag.ps {
  background-image: url("./flags/ps.svg") !important;
}
.flag.pa {
  background-image: url("./flags/pa.svg") !important;
}
.flag.pg {
  background-image: url("./flags/pg.svg") !important;
}
.flag.py {
  background-image: url("./flags/py.svg") !important;
}
.flag.pe {
  background-image: url("./flags/pe.svg") !important;
}
.flag.ph {
  background-image: url("./flags/ph.svg") !important;
}
.flag.pl {
  background-image: url("./flags/pl.svg") !important;
}
.flag.pt {
  background-image: url("./flags/pt.svg") !important;
}
.flag.pr {
  background-image: url("./flags/pr.svg") !important;
}
.flag.qa {
  background-image: url("./flags/qa.svg") !important;
}
.flag.re {
  background-image: url("./flags/re.svg") !important;
}
.flag.ro {
  background-image: url("./flags/ro.svg") !important;
}
.flag.ru {
  background-image: url("./flags/ru.svg") !important;
}
.flag.rw {
  background-image: url("./flags/rw.svg") !important;
}
.flag.kn {
  background-image: url("./flags/kn.svg") !important;
}
.flag.lc {
  background-image: url("./flags/lc.svg") !important;
}
.flag.vc {
  background-image: url("./flags/vc.svg") !important;
}
.flag.ws {
  background-image: url("./flags/ws.svg") !important;
}
.flag.sm {
  background-image: url("./flags/sm.svg") !important;
}
.flag.st {
  background-image: url("./flags/st.svg") !important;
}
.flag.sa {
  background-image: url("./flags/sa.svg") !important;
}
.flag.sn {
  background-image: url("./flags/sn.svg") !important;
}
.flag.rs {
  background-image: url("./flags/rs.svg") !important;
}
.flag.sc {
  background-image: url("./flags/sc.svg") !important;
}
.flag.sl {
  background-image: url("./flags/sl.svg") !important;
}
.flag.sg {
  background-image: url("./flags/sg.svg") !important;
}
.flag.sk {
  background-image: url("./flags/sk.svg") !important;
}
.flag.si {
  background-image: url("./flags/si.svg") !important;
}
.flag.sb {
  background-image: url("./flags/sb.svg") !important;
}
.flag.so {
  background-image: url("./flags/so.svg") !important;
}
.flag.za {
  background-image: url("./flags/za.svg") !important;
}
.flag.kr {
  background-image: url("./flags/kr.svg") !important;
}
.flag.ss {
  background-image: url("./flags/ss.svg") !important;
}
.flag.es {
  background-image: url("./flags/es.svg") !important;
}
.flag.lk {
  background-image: url("./flags/lk.svg") !important;
}
.flag.sd {
  background-image: url("./flags/sd.svg") !important;
}
.flag.sr {
  background-image: url("./flags/sr.svg") !important;
}
.flag.sz {
  background-image: url("./flags/sz.svg") !important;
}
.flag.se {
  background-image: url("./flags/se.svg") !important;
}
.flag.ch {
  background-image: url("./flags/ch.svg") !important;
}
.flag.sy {
  background-image: url("./flags/sy.svg") !important;
}
.flag.tw {
  background-image: url("./flags/tw.svg") !important;
}
.flag.tj {
  background-image: url("./flags/tj.svg") !important;
}
.flag.tz {
  background-image: url("./flags/tz.svg") !important;
}
.flag.th {
  background-image: url("./flags/th.svg") !important;
}
.flag.tl {
  background-image: url("./flags/tl.svg") !important;
}
.flag.tg {
  background-image: url("./flags/tg.svg") !important;
}
.flag.to {
  background-image: url("./flags/to.svg") !important;
}
.flag.tt {
  background-image: url("./flags/tt.svg") !important;
}
.flag.tn {
  background-image: url("./flags/tn.svg") !important;
}
.flag.tr {
  background-image: url("./flags/tr.svg") !important;
}
.flag.tm {
  background-image: url("./flags/tm.svg") !important;
}
.flag.tv {
  background-image: url("./flags/tv.svg") !important;
}
.flag.ug {
  background-image: url("./flags/ug.svg") !important;
}
.flag.ua {
  background-image: url("./flags/ua.svg") !important;
}
.flag.ae {
  background-image: url("./flags/ae.svg") !important;
}
.flag.gb {
  background-image: url("./flags/gb.svg") !important;
}
.flag.us {
  background-image: url("./flags/us.svg") !important;
}
.flag.uy {
  background-image: url("./flags/uy.svg") !important;
}
.flag.uz {
  background-image: url("./flags/uz.svg") !important;
}
.flag.vu {
  background-image: url("./flags/vu.svg") !important;
}
.flag.va {
  background-image: url("./flags/va.svg") !important;
}
.flag.ve {
  background-image: url("./flags/ve.svg") !important;
}
.flag.vn {
  background-image: url("./flags/vn.svg") !important;
}
.flag.ye {
  background-image: url("./flags/ye.svg") !important;
}
.flag.zm {
  background-image: url("./flags/zm.svg") !important;
}
.flag.zw {
  background-image: url("./flags/zw.svg") !important;
}