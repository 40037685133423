@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
}
/* CSS for HTML Ediotr Preview */

.editor-preview article,
.editor-preview aside,
.editor-preview details,
.editor-preview figcaption,
.editor-preview figure,
.editor-preview footer,
.editor-preview header,
.editor-preview hgroup,
.editor-preview main,
.editor-preview menu,
.editor-preview nav,
.editor-preview section,
.editor-preview summary,
.editor-preview dialog {
  display: block;
}

.editor-preview a:link,
.editor-preview a:visited {
  color: #000000; /* replace with your desired color */
  text-decoration: underline;
  cursor: auto;
}

.editor-preview a:link:active,
.editor-preview a:visited:active {
  color: #000000; /* replace with your desired color */
}

.editor-preview address {
  display: block;
  font-style: italic;
}

.editor-preview body {
  display: block;
  margin: 8px;
}

.editor-preview body:focus,
.editor-preview html:focus,
.editor-preview iframe:focus,
.editor-preview object:focus {
  outline: none;
}

.editor-preview blockquote {
  display: block;
  margin: 1em 40px;
}

.editor-preview caption {
  display: table-caption;
  text-align: center;
}

.editor-preview cite,
.editor-preview dfn,
.editor-preview i,
.editor-preview var {
  font-style: italic;
}

.editor-preview code,
.editor-preview kbd,
.editor-preview pre,
.editor-preview samp {
  font-family: monospace;
}

.editor-preview dd {
  display: block;
  margin-left: 40px;
}

.editor-preview del,
.editor-preview s,
.editor-preview strike {
  text-decoration: line-through;
}

.editor-preview details,
.editor-preview div,
.editor-preview dt {
  display: block;
}

.editor-preview dl {
  display: block;
  margin: 1em 0;
}

.editor-preview em {
  font-style: italic;
}

.editor-preview fieldset {
  display: block;
  margin: 2px;
  padding: 0.35em 0.75em;
  border: 2px groove; /* replace with your desired color */
}

.editor-preview figcaption {
  display: block;
}

.editor-preview figure {
  display: block;
  margin: 1em 40px;
}

.editor-preview footer {
  display: block;
}

.editor-preview form {
  display: block;
}

.editor-preview h1,
.editor-preview h2,
.editor-preview h3,
.editor-preview h4,
.editor-preview h5,
.editor-preview h6 {
  display: block;
  margin: 0;
  font-weight: bold;
}

.editor-preview h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

.editor-preview h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

.editor-preview h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

.editor-preview h4 {
  margin: 1.33em 0;
}

.editor-preview h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

.editor-preview h6 {
  font-size: 0.67em;
  margin: 2.33em 0;
}

.editor-preview header {
  display: block;
}

.editor-preview hr {
  display: block;
  margin: 0.5em auto;
  border-style: inset;
  border-width: 1px;
}

.editor-preview img {
  display: inline-block;
}

.editor-preview ins {
  text-decoration: underline;
}

.editor-preview label {
  cursor: default;
}

.editor-preview legend {
  display: block;
  padding: 0 2px;
  border: none;
}

.editor-preview li {
  display: list-item;
}

.editor-preview mark {
  background-color: yellow;
  color: black;
}

.editor-preview nav {
  display: block;
}

.editor-preview ol,
.editor-preview ul {
  display: block;
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 40px;
}

.editor-preview output {
  display: inline;
}

.editor-preview p {
  display: block;
  margin: 1em 0;
}

.editor-preview q {
  display: inline;
}

.editor-preview q::before,
.editor-preview q::after {
  content: '"';
}

.editor-preview rt {
  line-height: normal;
}

.editor-preview strong {
  font-weight: bold;
}

.editor-preview sub {
  vertical-align: sub;
  font-size: smaller;
}

.editor-preview sup {
  vertical-align: super;
  font-size: smaller;
}

.editor-preview table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}

.editor-preview tbody,
.editor-preview tfoot,
.editor-preview thead {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.editor-preview td,
.editor-preview th {
  display: table-cell;
  vertical-align: inherit;
}

.editor-preview th {
  font-weight: bold;
  text-align: center;
}

.editor-preview tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.editor-preview u {
  text-decoration: underline;
}

.editor-preview small {
  font-size: smaller;
}

.sidebar-gradient {
  background: linear-gradient(109deg, #304847 5%, #232c37 30%, #1c242c 90%);
}

.header-gradient {
  background: linear-gradient(191deg, rgba(48, 72, 71, 0.7), rgba(35, 44, 55, 0.9), rgba(28, 36, 44, 0.9));
}

/* TODO: re-implement the gradient */
.button-gradient {
  background: linear-gradient(150deg, #82e6b8 60%, #4963b4 100%);
}

.button-gradient:hover {
  background: linear-gradient(150deg, #529f7c 60%, #253b7e 100%);
}

.button-gradient-destructive {
  background: linear-gradient(129deg, #962c30 44.81%, #ff9296 109.17%);
}

.option-selector-gradient-1 {
  background: linear-gradient(179deg, #7898fb 0%, #7085a8 100%);
}

.option-selector-gradient-2 {
  background: linear-gradient(179deg, #82e6b8 0%, #4963b4 100%);
}

.option-selector-gradient-3 {
  background: linear-gradient(179deg, #f2eb37 0%, #ff9296 100%);
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  display: none;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../public/images/calendarIcon.svg') no-repeat;
  width: 24px;
  height: 24px;
  border-width: thin;
  margin-top: 3px;
  margin-right: -5px;
  cursor: pointer;
}

/* TODO: povecati height containera editora na 100%.. */
.cm-editor {
  height: 100% !important;
}
